<template>
  <div>
    <KnowledgeSelectedSideBarVue
      :is-sidebar-active="isKnowledgeSelectedSideBarActive"
      :items="selected"
      :deleted="deleted"
      :selected-avalibel-save="selectedAvalibelSave"
      :current-category-id="currentCategoryId"
      :category-level="categoryLevel"
      :lvl-one-options="lvlOneOptions"
      :lvl-two-options="lvlTwoOptions"
      :lvl-three-options="lvlThreeOptions"
      :lvl-four-options="lvlFourOptions"
      :lvl-five-options="lvlFiveOptions"
      :lvl-six-options="lvlSixOptions"
      @update:side-bar="isKnowledgeSelectedSideBarActive = $event"
      @update:items="items = $event"
      @selected-category-item="selectedItem"
      @add-knowledge-category="onSaveKnowledgeCategory()"
      @delete-knowledge-category="onDeleteKnowledgeCategory()"
    />
    <b-card>
      <b-card-title>Categories</b-card-title>
      <b-row class="align-items-end">
        <b-col
          sm="12"
          md="4">
          <SelectField
            v-model="categoryLevel.categorySearchLvlOne"
            :disabled="!lvlOneOptions || lvlOneOptions.length <= 0"
            name="Category level 1"
            form-label="Category level 1"
            placeholder="Category level 1"
            :clearable="false"
            return-value="id"
            :rules="{ required: true }"
            label-option="name"
            :options="lvlOneOptions"
            @input="selectedItem($event, 1)"
          />
        </b-col>
        <b-col
          sm="12"
          md="4">
          <SelectField
            v-model="categoryLevel.categorySearchLvlTwo"
            :disabled="!lvlTwoOptions || lvlTwoOptions.length <= 0"
            name="Category level 2"
            form-label="Category level 2"
            placeholder="Category level 2"
            :options="lvlTwoOptions"
            return-value="id"
            label-option="name"
            @input="selectedItem($event, 2)"
          />
        </b-col>
        <b-col
          sm="12"
          md="4">
          <SelectField
            v-model="categoryLevel.categorySearchLvlThree"
            :disabled="!lvlThreeOptions || lvlThreeOptions.length <= 0"
            name="Category level 3"
            form-label="Category level 3"
            placeholder="Category level 3"
            :options="lvlThreeOptions"
            return-value="id"
            label-option="name"
            @input="selectedItem($event, 3)"
          />
        </b-col>
        <b-col
          sm="12"
          md="4">
          <SelectField
            v-model="categoryLevel.categorySearchLvlFour"
            :disabled="!lvlFourOptions || lvlFourOptions.length <= 0"
            name="Category level 4"
            form-label="Category level 4"
            placeholder="Category level 4"
            :options="lvlFourOptions"
            return-value="id"
            label-option="name"
            @input="selectedItem($event, 4)"
          />
        </b-col>
        <b-col
          sm="12"
          md="4">
          <SelectField
            v-model="categoryLevel.categorySearchLvlFive"
            :disabled="!lvlFiveOptions || lvlFiveOptions.length <= 0"
            name="Category level 5"
            form-label="Category level 5"
            placeholder="Category level 5"
            :options="lvlFiveOptions"
            return-value="id"
            label-option="name"
            @input="selectedItem($event, 5)"
          />
        </b-col>
        <b-col
          sm="12"
          md="4">
          <SelectField
            v-model="categoryLevel.categorySearchLvlSix"
            :disabled="!lvlSixOptions || lvlSixOptions.length <= 0"
            name="Category level 6"
            form-label="Category level 6"
            placeholder="Category level 6"
            :options="lvlSixOptions"
            return-value="id"
            label-option="name"
            @input="selectedItem($event, 6)"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="currentCategoryId"
      class="mt-2">
      <div class="d-flex justify-content-between">
        <b-card-title>Knowledge Base</b-card-title>
        <b-button
          v-if="selected.length > 0"
          :disabled="!currentCategoryId"
          size="sm"
          variant="primary"
          @click="isKnowledgeSelectedSideBarActive = !isKnowledgeSelectedSideBarActive">
          Selected <b-badge variant="light">
            <span style="color: black">
              {{ selected.length }}
            </span>
          </b-badge>
        </b-button>
      </div>
      <KnowledgeFilter
        :search.sync="filter.searchCodeAndSubject"
        :status-filter.sync="filter.status"
        :search-tags.sync="filter.searchTags"
        @fetch-filter="fetchFilter()" />
      <b-row class="my-2">
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>Show</label>
          <v-select
            v-model="pagination.limit"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
            @input="fetchFilter()" />
          <label>entries</label>
        </b-col>
        <b-col
          cols="12"
          md="6">
          <div class="d-block d-sm-flex align-items-center justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="!currentCategoryId"
              :variant="selected && selected.length > 0 ? 'danger' : 'primary'"
              @click="selected && selected.length > 0 ? clearSelected() : selectAllRows() ">
              <span class="align-middle"> {{ selected && selected.length > 0 ? 'Cancel All': 'Select All' }}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="!currentCategoryId || deleted.length <= 0"
              class="mx-1"
              variant="danger"
              @click="onDeleteKnowledgeCategory()">
              <span class="align-middle">Delete ({{ deleted.length }})</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="!currentCategoryId || selected.length <= 0"
              class=""
              variant="success"
              @click="onSaveKnowledgeCategory()">
              <span class="align-middle">Save ({{ selectedAvalibelSave.length }})</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-table
        ref="selectableTable"
        select-mode="multi"
        responsive="sm"
        selectable
        :items="items"
        :fields="fields"
        @row-selected="onRowSelected"
      >
        <template #cell(knowledgeType)="{ item }">
          <div
            v-if="item && item.knowledgeType"
            class="font-weight-semibold">
            {{
              item.knowledgeType.title
            }}
          </div>
        </template>
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </template>
        <template #cell(isInCategory)="{ item }">
          <b-badge
            v-if="checkItemInCategory(currentCategoryId ,item.categories)"
            variant="danger">
            Already In Category
          </b-badge>
        </template>
        <template #cell(code)="data">
          <span class="font-weight-semibold text-primary">#{{ data.value }}</span>
        </template>
        <template #cell(subject)="data">
          <span class="font-weight-semibold">{{ data.value }}</span>
        </template>
        <template #cell(problemDetail)="data">
          <div
            class="font-weight-semibold wrap-text"
            v-html="$wrapWords(data.value, 5)">
          </div>
        </template>
        <template #cell(solutionDetail)="data">
          <span
            class="font-weight-semibold wrap-text"
            v-html="$wrapWords(data.value, 5)"></span>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import SelectField from '@/components/Form/SelectField.vue'
import CategoriesProvider from '@/resources/CategoriesProvider'
import KnowledgeProvider from '@/resources/KnowledgeBaseProvider'
import KnowledgeFilter from '@/components/Others/Knowledge/KnowledgeFilter.vue'
import KnowledgeBaseCategoryProvider from '@/resources/KnowledgeBaseCategoryProvider'
import KnowledgeSelectedSideBarVue from './Components/KnowledgeSelectedSideBar.vue'

const CategoriesService = new CategoriesProvider()
const KnowledgeService = new KnowledgeProvider()
const KnowledgeBaseCategoryService = new KnowledgeBaseCategoryProvider()

export default {
  components: {
    SelectField,
    KnowledgeFilter,
    KnowledgeSelectedSideBarVue
  },
  data () {
    return {
      perPageOptions: [10, 20, 50, 100, 500, 1000],
      isKnowledgeSelectedSideBarActive: false,
      selected: [],
      deleted: [],
      selectedAvalibelSave: [],
      refreshIsInCategory: true,
      filter: {
        searchCodeAndSubject: '',
        status: '',
        searchTags: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'selected', label: '' },
        { key: 'code', label: 'Code', sortable: true },
        { key: 'subject',
          label: ' Subject',
          thStyle: {
            width: '20rem'
          } },
        { key: 'problemDetail', label: ' Problem' },
        { key: 'solutionDetail', label: ' Solution' },
        { key: 'knowledgeType', label: 'Type' },
        // { key: 'updatedAt', label: 'Updated At', sortable: true },
        // { key: 'updatedBy', label: 'Updated By' },
        { key: 'isInCategory', label: '' }
      ],
      items: [
      ],
      knowledge: {
        code: '',
        subject: '',
        problemDetail: '',
        solutionDetail: '',
        problemFile: '',
        solutionFile: '',
        categoryId: ''
      },
      mockData: [
        { label: '#001', value: 0 },
        { label: '#002', value: 1 },
        { label: '#003', value: 2 },
        { label: '#004', value: 3 },
        { label: '#005', value: 4 }
      ],
      files: {
        problemFile: [],
        solutionFile: []
      },
      lvlOneOptions: [],
      lvlTwoOptions: [],
      lvlThreeOptions: [],
      lvlFourOptions: [],
      lvlFiveOptions: [],
      lvlSixOptions: [],
      categories: [],
      categoryLevel: {
        categorySearchLvlOne: '',
        categorySearchLvlTwo: '',
        categorySearchLvlThree: '',
        categorySearchLvlFour: '',
        categorySearchLvlFive: '',
        categorySearchLvlSix: ''
      },
      options: [
        { text: 'Orange', value: 'orange' },
        { text: 'Apple', value: 'apple' },
        { text: 'Pineapple', value: 'pineapple' },
        { text: 'Grape', value: 'grape' }
      ]
    }
  },
  computed: {
    currentCategoryId () {
      return this.getLastItem(this.categoryLevel)
    }
  },
  created () {
    this.getAllCategories()
    this.getKnowledge()
  },
  methods: {
    async getAllCategories () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const { data } = await CategoriesService.findAll()
        this.categories = data
        this.lvlOneOptions = data.filter((item) => item.level === 1)
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async getKnowledge () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const data = await KnowledgeService.paginate(this.pagination.page, this.pagination.limit, this.filter)
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        console.error(err)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async onSaveKnowledgeCategory () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const { selected } = this
        const categoryLevel = this.getLastItem(this.categoryLevel)
        if (Array.isArray(selected) && selected.length > 0 && categoryLevel) {
          // const knowledgeBaseIds = selected.map((item) => item?.id)
          const knowledgeBaseIds = []
          selected.forEach((item) => {
            const checkItemInCategory = this.checkItemInCategory(this.currentCategoryId, item?.categories)
            if (!checkItemInCategory) {
              knowledgeBaseIds.push(item.id)
            }
          })
          const payload = {
            knowledgeBaseIds,
            categoryId: categoryLevel
          }
          await KnowledgeBaseCategoryService.create(payload)
        }
        await this.getAllCategories()
        await this.getKnowledge()
        this.refreshRowSelected()
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async onDeleteKnowledgeCategory () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const { deleted } = this
        if (Array.isArray(deleted) && deleted.length > 0) {
          const knowledgeBaseIds = deleted.map((item) => item?.id)
          if (knowledgeBaseIds.length > 0 && this.currentCategoryId) {
            const payload = {
              knowledgeBaseIds,
              categoryId: this.currentCategoryId
            }
            await KnowledgeBaseCategoryService.hardDelete(payload)
          }
          await this.getAllCategories()
          await this.getKnowledge()
          this.refreshRowSelected()
          this.isKnowledgeSelectedSideBarActive = false
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    onRowSelected (items) {
      this.selected = items
      if (Array.isArray(items)) {
        this.deleted = []
        this.selectedAvalibelSave = []
        items.forEach((item) => {
          const checkItemInCategory = this.checkItemInCategory(this.currentCategoryId, item?.categories)
          if (!checkItemInCategory) {
            // this.selected.push(item)
            this.selectedAvalibelSave.push(item)
          } else {
            this.deleted.push(item)
          }
        })
      }
    },
    refreshRowSelected () {
      const items = this.selected
      this.selected = items
      if (Array.isArray(items)) {
        this.deleted = []
        this.selectedAvalibelSave = []
        items.forEach((item) => {
          const checkItemInCategory = this.checkItemInCategory(this.currentCategoryId, item?.categories)
          if (!checkItemInCategory) {
            // this.selected.push(item)
            this.selectedAvalibelSave.push(item)
          } else {
            this.deleted.push(item)
          }
        })
      }
    },
    selectAllRows () {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected () {
      this.$refs.selectableTable.clearSelected()
    },
    fetchFilter () {
      this.setPagination(1)
      this.getKnowledge()
    },
    rowClick (val) {
      this.$router.push(`/other/knowledge/${val.id}`)
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getKnowledge()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs === 0 ? 0 : totalDocs || this.pagination.totalDocs
    },
    selectedItem (parentId, level) {
      const filter = this.findCategory(parentId)
      switch (level) {
      case 1:
        this.lvlTwoOptions = filter
        this.lvlThreeOptions = []
        this.lvlFourOptions = []
        this.lvlFiveOptions = []
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlTwo = null
        this.categoryLevel.categorySearchLvlThree = null
        this.categoryLevel.categorySearchLvlFour = null
        this.categoryLevel.categorySearchLvlFive = null
        this.categoryLevel.categorySearchLvlSix = null
        break
      case 2:
        this.lvlThreeOptions = filter
        this.lvlFourOptions = []
        this.lvlFiveOptions = []
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlThree = null
        this.categoryLevel.categorySearchLvlFour = null
        this.categoryLevel.categorySearchLvlFive = null
        this.categoryLevel.categorySearchLvlSix = null
        break
      case 3:
        this.lvlFourOptions = filter
        this.lvlFiveOptions = []
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlFour = null
        this.categoryLevel.categorySearchLvlFive = null
        this.categoryLevel.categorySearchLvlSix = null
        break
      case 4:
        this.lvlFiveOptions = filter
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlFive = null
        this.categoryLevel.categorySearchLvlSix = null
        break
      case 5:
        this.lvlSixOptions = filter
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlSix = null
        break
      default:
        break
      }
      this.refreshRowSelected()
    },
    findCategory (parentId) {
      return this.categories.filter((item) => item.parentId === parentId)
    },
    getLastItem (obj) {
      const convertToArray = Object.keys(obj).map((key) => obj[key])
      let result = null
      convertToArray.forEach((item) => {
        if (item && item !== '') {
          result = item
        }
      })
      return result
    },
    checkItemInCategory (categoryId, categoriesItems) {
      if (Array.isArray(categoriesItems) && categoriesItems.length > 0) {
        const find = categoriesItems?.find((item) => item?.id === categoryId)
        if (find) {
          return true
        }
      }
      return false
    }
  }
}
</script>
<style scoped>
.clamped-text {
  width: 30px;
  overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
   -webkit-box-orient: vertical;
}
</style>
